@font-face {
    font-family: 'Ofelia';
    src: url('../src/assets/fonts/OfeliaText-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OfeliaLight';
    src: url('../src/assets/fonts/OfeliaText-Light.woff2') format('woff2');
    font-weight: lighter;
    font-style: normal;
}

@font-face {
    font-family: 'OfeliaBold';
    src: url('../src/assets/fonts/OfeliaDisplay-Medium.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

body {
    font-family: 'Ofelia', sans-serif !important;
}